import React, {useContext, useEffect, useState} from 'react'
import Context from 'gatsby-theme-psg/src/components/Events/Context'
import Ticket from 'gatsby-theme-psg/src/components/Tickets/Ticket'
import moment from 'moment'
import {Carousel, Col, Row} from 'react-bootstrap'
import {ticketParams} from '../../params'
import 'gatsby-theme-psg/src/components/Tickets/style.scss'

const Tickets = ({pageSize= 4}) => {
    const {state} = useContext(Context)
    const {event} = state
    const tickets = event.frontmatter.tickets

    const [earlyBird, setEarlyBird] = useState(false)
    useEffect(() => {
        setEarlyBird(moment().isBefore(moment(event.frontmatter.early_bird)))
    }, [event.frontmatter.early_bird])

    return (
        <>
            {tickets ?
                <Carousel
                    className={"my-5"}
                    indicators={false}
                >
                    {[...Array(Math.ceil(tickets.length/pageSize))].map((e, i) =>
                        <Carousel.Item key={i}>
                            <Row className={'justify-content-center tickets'}>
                                {tickets.slice(i*pageSize, i*pageSize+pageSize).map((ticket, j) =>
                                    <Col {...ticketParams.colProps} key={j} className={`mb-4 ticket-col`}>
                                        <Ticket ticket={ticket} early_bird={earlyBird && ticket.price_early} />
                                    </Col>
                                )}
                            </Row>
                        </Carousel.Item>
                    )}
                </Carousel>
                :
                <h3 className="text-center">{ticketParams.emptyText}</h3>
            }
        </>
    )
}

export default Tickets