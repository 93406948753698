import React, {useState} from 'react'
import Layout from 'gatsby-theme-psg/src/components/Layout'
import Header from '../components/header/Header'
import Lineup from 'gatsby-theme-psg/src/components/Lineup'
import Gallery from 'gatsby-theme-psg/src/components/Gallery'
import Tickets from 'gatsby-theme-psg/src/components/Tickets'
import {graphql, Link} from 'gatsby'
import Img from "gatsby-image";
import {Container, Modal} from "react-bootstrap";
import SectionButton from "gatsby-theme-psg/src/components/Section/Button";
import {
    FaPlayCircle
} from 'react-icons/fa'
import Button from "react-bootstrap/Button";
import Context from "gatsby-theme-psg/src/components/Events/Context";
import Content from "gatsby-theme-psg/src/components/Content";
import {summaryParams} from "gatsby-theme-psg/src/params";

const Index = ({ data }) => {
    const { markdownRemark, file, section, video } = data
    const [open, setOpen] = useState(false)

    return (
        <Layout template={'template-home'} title={markdownRemark.frontmatter.title} description={markdownRemark.frontmatter.description}>
            <Header height='calc(100vh)'>
                <Img
                    fluid={data.logo.childImageSharp.fluid}
                    style={{
                        width: '80%',
                        maxWidth: 640
                    }}
                    className={'mx-auto'}
                />
                <Context.Consumer>
                    {({state}) => (
                        <>
                            <div
                                className={'text-uppercase'}
                            >
                            <span
                                className={'text-secondary'}
                                style={{
                                    fontSize: 72,
                                    textShadow: '0 0 6px rgba(0, 0, 0, 0.96)'
                                }}
                            >{state.event.frontmatter.location}</span>
                                <span
                                    className={'text-success'}
                                    style={{
                                        fontSize: 72,
                                        textShadow: '0 0 12px #ffffff'
                                    }}

                                > {state.event.frontmatter.dateMedium}</span>
                            </div>
                            <Content content={state.event.html} />
                            {state.event.frontmatter.status === 'In verkoop' ?
                                <Button as={Link} to={"/tickets"} {...summaryParams.ticketButton.props}>
                                    {summaryParams.ticketButton.text}
                                </Button>
                                :
                                <span className="text-danger status h2">[{state.event.frontmatter.status}]</span>
                            }
                        </>
                    )}
                </Context.Consumer>
            </Header>
            <div
                className={'position-relative'}
                style={{
                    marginTop: -100,
                }}
            >
                <Img
                    fluid={file.childImageSharp.fluid}
                    style={{
                        width: '100%',
                        height: '1600',
                        minHeight: '120vh'
                    }}
                    objectFit="cover"
                    objectPosition="bottom left"
                />
                <Container
                    fluid={'xl'}
                    className={'position-absolute'}
                    style={{
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <section
                        title="Lineup"
                        className={'my-5'}
                    >
                        <heading>
                            <h2
                                className={'text-secondary'}
                                style={{
                                    textShadow: '2px 0 0 #fe7600, -2px 0 0 #1a483d'
                                }}
                            >
                                <span>Artiesten</span>
                            </h2>
                        </heading>
                        <Lineup />
                        <footer className="text-center my-5">
                            <SectionButton as={Link} to={"/lineup"}>
                                Volledige line-up
                            </SectionButton>
                        </footer>
                    </section>
                </Container>
            </div>
            <div
                className={'position-relative'}
            >
                <Img
                    fluid={section.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="bottom left"
                    style={{
                        minHeight: '100vh'
                    }}
                />
                <Container
                    fluid={'xl'}
                    className={'position-absolute'}
                    style={{
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <section
                        title="Tickets"
                        className={'my-5'}
                    >
                        <heading>
                            <h2
                                className={'text-success'}
                                style={{
                                    textShadow: '2px 0 0 #fe7600, -2px 0 0 #0effa5'
                                }}
                            >
                                <span>Tickets</span>
                            </h2>
                        </heading>
                        <div className="d-none d-md-block text-light">
                            <Tickets />
                        </div>
                        <div className="d-block d-md-none">
                            <Tickets pageSize={1} />
                        </div>
                        <footer className="text-center my-5">
                            <SectionButton as={Link} to={"/tickets"}>
                                Alle tickets
                            </SectionButton>
                        </footer>
                    </section>
                </Container>
            </div>
            <div
                className={'position-relative'}
            >
                <Img
                    fluid={video.childImageSharp.fluid}
                />
                <Container
                    fluid={'xl'}
                    className={'position-absolute'}
                    style={{
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <Button variant="link" className={'text-white'} onClick={() => setOpen(true)}>
                        <FaPlayCircle />
                        <br />
                        Bekijk de aftermovie
                    </Button>

                    <Modal show={open} size={'lg'} onHide={() => setOpen(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Aftermovie</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <iframe
                                title={'aftermovie'}
                                width="100%"
                                height="500"
                                src="https://www.youtube.com/embed/RTyMVYZ3Nh4"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen />
                        </Modal.Body>
                    </Modal>
                </Container>
            </div>
            <section
                style={{
                    backgroundColor: '#081d1b'
                }}
                className={'pb-5'}
            >
                <Container
                    fluid={'lg'}
                    className={'mt-n5'}
                >
                    <div className="d-none d-md-block text-light">
                        <Gallery images={markdownRemark.frontmatter.images} imagesPerPage="3" />
                    </div>
                    <div className="d-block d-md-none">
                        <Gallery images={markdownRemark.frontmatter.images} imagesPerPage="1" />
                    </div>
                </Container>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query IndexPage {
        logo: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
                fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        file(relativePath: { eq: "background@2x.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 2048, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
                fixed(fit: COVER) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        video: file(relativePath: { eq: "video/video@3x.jpg" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 2048, quality: 90, fit: CONTAIN) {
                    ...GatsbyImageSharpFluid_withWebp
                }
                fixed(fit: COVER) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        section: file(relativePath: { eq: "section-background@3x.jpg" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxHeight: 1000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
            html
            frontmatter {
                title
                description
                images {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 900, maxHeight: 600, quality: 90) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    alt
                }
            }
        }
    }
`

export default Index